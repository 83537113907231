export default {
  'addDowntime': 'Füge Stillstandszeit hinzu',
  addLineDowntime: 'Leitungsausfallzeiten hinzufügen',
  addMachineDowntime: '{name} Ausfallzeit hinzufügen',
  'newDowntime': 'neue Stillstandszeit',
  'actionsAndReasons': 'Maßnahmen und Ursachen',
  'performActions': 'Füge folgende Aktion aus:',
  'secifyReasonIfKnown': 'Ursache angeben',
  'downtimelist': 'Ursache Stillstandszeit',
  'annotations': 'Anmerkungen',
  'categories': 'Kategorien',
  'clearRootCause': 'Ursache löschen',
  'reason': 'Ursache',
  'reasons': 'Ursachen',
  'rootCauseReason': 'Grundursache',
  'noReasons': 'keine Ursache definiert',
  'noCorrectiveActions': 'keine Korrekturmaßnahme definiert',
  'noEvents': 'keine Ereignis definiert',
  'noCameras': 'keine Kamera definiert',
  'removeReasonWorning': 'Warnung! Alle mit dieser Ursache verbundenen Korrekturmaßnahmen werden entfernt!',
  'addReason': 'als neue Ursache',
  'selectEvent': 'Ereignis / Problem',
  'newCorrectiveAction': 'neue Korrekturmaßnahme',
  'newReason': 'Neue Ursache',
  'correctiveAction': 'Kerrekturmaßnahme',
  'correctiveActions': 'Korrekturmaßnahmen',
  'callForService': 'Service kontaktieren',
  'serviceHasBeenCalled': 'Service wurde gerufen',
  'specifyRootcause': 'Hauptursache',
  'lastDowntimes': 'letzte Stillstandszeiten',
  'startTime': 'gestartet',
  'endTime': 'beendet',
  'externalReason': 'Externe Ursache',
  'uncategorized': 'nicht kategorisiert',
  'noActions': 'keine Aktionen auszuführen',
  'timeSlot': 'Zeitfenster',
  'selectOtherMachineDowntime': 'andere Stillstandszeit auswählen',
  'selectMachine': 'Maschine auswählen',
  'workEventFilter': 'Ereignis Filter',
  'deselectAll': 'alle abwählen',
  'connect': 'verbinde',
  'commonReason': 'häufiger Grund',
  'commonProblem': 'häufiges Problem',
  'clearSelection': 'lösche Auswahl',
  'noCorrectiveAction': 'Keine Korrekturmaßnahme ergriffen',
  'toEditSetCommonWorkEvent': 'Zum bearbeiten wähle Arbeitsereignis',
  'differentCorrectiveActions': 'Verschiedene Korrekturmaßnahmen',
  'differentReasons': 'Verschiedene Ursachen',
  'noReason': 'kein Grund',
  'addOrCreateReason': 'zugehörigen Grund auswählen',
  'lineState': 'Linien Status',
  'selectProblemFirst': 'Problem zuerst auswählen',
  'setProblem': 'Problem auswählen',
  'setReason': 'Grund auswählen',
  'setCorrectiveAction': 'Korrekturmaßnahme auswählen',
  'summary': 'Zusammenfassung',
  'deleteConfirmMessage': 'Bestätigen Sie, dass Sie ausgewählte Stillstandszeiten löschen möchten.',
  'changeDuration': 'Dauer ändern',
  'downtimeUpdated': 'Stillstandszeit aktualisiert',
  'noMachineDowntime': 'Gilt nicht bei Maschinen',
  'setAction': 'Maßnahmen festlegen',
  'addAnotherCorrectiveAction': 'Weitere Korrektumaßnahme hinzufügen',
  'addAnotherReason': 'Weitere Ursache hinzufügen',
  noDetails: 'Keine Details',
  noClipsCameraAvailable: 'Keine Clips angefordert, Kamera vorhanden',
  clipsDownloaded: 'Angeforderte Clips',
  stopLine: 'Stoppen die Linie',
  stopLineStep: 'Stoppen die Linie - Stufe {step}',
};
